<template>
    <div class="filter-actions-container">
        <Button bType="clearFilters" v-if="showClearFiltersButton" :bCallback="clearFiltersCallback"> </Button>
        <Button bType="filter" :bCallback="filterCallback" :bLabel="filterButtonLabel"> </Button>
    </div>
</template>

<script>
import Button from './Button.vue'

export default {
    name: 'FilterActions',
    components: { Button },
    props: {
        showClearFiltersButton: { type: Boolean },
        clearFiltersCallback: { type: Function },
        filterCallback: { type: Function },
        changesDetected: { type: Boolean }
    },
    computed: {
        filterButtonLabel() {
            return this.changesDetected ? 'assets.filter.filter' : 'assets.filter.refresh'
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-actions-container {
    width: 100%;
    max-width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;

    display: flex;
    flex-direction: column;
    gap: 0.5em;

    padding-right: 0;
    background-color: inherit;
}
</style>
