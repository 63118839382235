<template>
    <div id="content" class="issue-state-management">
        <div class="bar-actions">
            <TranslateButton :entityId="'timeframe.' + timeframeRender.id" :translationLangs="timeframeRender.translations" @newTranslation="newTranslation" @deleteTranslation="deleteTranslation"></TranslateButton>
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveTimeframe" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showConfirmDeleteTimeframeModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="timeframeRender">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('user.user_name') }}</h2>
                        <div class="user-answer">
                            <TranslateInput :translation_keyname="'timeframe.' + timeframeRender.id + '.name'" @input="updateValues($event)" v-model="timeframeRender.name" :gOptions="{ mandatory: true }" :_placeholder="$t('issueState.placeholder_name')" :gMessage="{ error: $t('product.product_state.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></TranslateInput>
                            <!-- <Input @input="updateValues($event)" v-model="timeframeRender.name" :gOptions="{ mandatory: true }" :gPlaceholder="$t('issueState.placeholder_name')" :gLabel="$t('user.user_name')" :gMessage="{ error: $t('product.product_state.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></Input> -->
                        </div>
                    </div>
                    <div class="right-container translate-disable">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="timeframeRender.status ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="timeframeRender.status" disabled="true" />
                            </div>
                        </div>
                        <!-- <p class="description" v-html="$t('issueState.switch_description')"></p> -->
                    </div>
                </div>
                <div class="label-container">
                    <!-- horas -->
                    <div class="left-container two-items translate-disable">
                        <div class="error-form hour" v-if="errors.start_hour" v-html="$t('timeframes.form.error_start_hour')"></div>
                        <div class="error-form hour" v-if="errors.end_hour" v-html="$t('timeframes.form.error_end_hour')"></div>
                        <div class="hour-container">
                            <div class="user-answer">
                                <div class="label">
                                    {{ $t('timeframes.form.start_hour') }}
                                </div>
                                <div class="hour-box">
                                    <input class="hour" type="time" step="1800" v-model="from_formatted" @input="timeframeHoursFormat('start', $event)" />
                                </div>
                            </div>
                            <div class="user-answer">
                                <div class="label">
                                    {{ $t('timeframes.form.end_hour') }}
                                </div>
                                <div class="hour-box">
                                    <input class="hour" type="time" step="1800" v-model="to_formatted" @input="timeframeHoursFormat('end', $event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-container"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'TimeFramesForm',
    props: { id: undefined },
    components: {},
    data() {
        return {
            sendForm: true,
            errors: {
                name: false,
                start_hour: false,
                end_hour: false
            },
            idTimeFrame: false,
            form: false,
            moreOptions: false,
            from_formatted: '',
            to_formatted: '',
            from_mode: '',
            to_mode: ''
        }
    },
    computed: {
        timeframeRender: {
            get() {
                if (this.idTimeFrame) {
                    var timeframe = this.$store.getters['timeframes/getTimeFrames'](this.idTimeFrame)
                    if (timeframe.status == -2) {
                        timeframe.status = 0
                    }
                    return timeframe
                }
            },
            set(value) {
                this.$store.commit('timeframes/updateTimeFrame', value)
            }
        }
    },
    methods: {
        newTranslation(code) {
            this.$set(this.timeframeRender.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.timeframeRender.translations, code)
        },
        // Method for make changes from form to store object
        updateValues(v) {
            // this.timeframeRender.name = v
            this.timeframeRender = this.timeframeRender
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        timeframeHoursFormat(type, event = false) {
            var value = event ? event.target.value : type == 'start' ? this.from_formatted : this.to_formatted
            var hour = parseInt(value.split(':')[0])
            var minutes = value.split(':')[1] ? parseFloat(value.split(':')[1]) / 60 : 0

            if (minutes > 0.1 || value.split(':')[1] == '00') {
                if (minutes > 0.25 && minutes < 0.7) {
                    var minutesParsed = 0.5
                    var minutesFormatted = '30'
                } else if (minutes >= 0.7 && minutes < 0.9) {
                    var minutesParsed = 0.75
                    var minutesFormatted = '45'
                } else if (minutes >= 0.9) {
                    var minutesParsed = 0.0
                    var minutesFormatted = '00'
                    var hour = parseInt(hour) + 1
                } else if (minutes > 0.1) {
                    var minutesParsed = 0.25
                    var minutesFormatted = '15'
                } else {
                    var minutesParsed = 0.0
                    var minutesFormatted = '00'
                }

                if (type == 'start') {
                    this.from_formatted = (hour.toString().length < 2 ? '0' + hour : hour) + ':' + minutesFormatted
                    this.timeframeRender.start_hour = hour + minutesParsed
                } else {
                    this.to_formatted = (hour.toString().length < 2 ? '0' + hour : hour) + ':' + minutesFormatted
                    this.timeframeRender.end_hour = hour + minutesParsed
                }

                if (this.from_mode == 24 || (parseInt(this.from_formatted.split(':')[0]) >= parseInt(this.to_formatted.split(':')[0]) && parseInt(this.to_formatted.split(':')[0])) >= 24) {
                    this.to_mode = 24
                }

                this.timeframeRender.start_hour = this.from_mode < 0 && this.timeframeRender.start_hour < 24 ? parseInt(this.from_mode) + parseInt(this.timeframeRender.start_hour) : this.timeframeRender.start_hour
                this.timeframeRender.end_hour = this.from_mode < 0 && this.timeframeRender.end_hour < 24 ? parseInt(this.from_mode) + parseInt(this.timeframeRender.end_hour) : this.timeframeRender.end_hour
            }
        },
        formatHours(tpl) {
            var template = { ...tpl }
            template.start_hour = tpl.start_hour && tpl.start_hour != null ? tpl.start_hour : tpl.start_hour
            template.end_hour = tpl.end_hour && tpl.end_hour != null ? tpl.end_hour : tpl.end_hour
            this.from_formatted = timeFormat(template.start_hour)

            this.to_formatted = timeFormat(template.end_hour)

            this.from_mode = parseInt('-' + Math.floor(Math.abs(template.start_hour) / 24) * 24)
            this.to_mode = Math.floor(Math.abs(template.end_hour) / 24) * 24
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },
        setColor(color) {
            this.timeframeRender.color = color
            this.updateValues(color)
        },

        popupStatus() {
            var self = this
            if (self.timeframeRender.status == '1' || self.timeframeRender.status == true) {
                self.$popup.confirm({
                    message: self.$t('timeframe.status_inactive'),
                    textSave: self.$t('timeframe.status_inactive_save'),
                    callSave: function () {
                        self.timeframeRender.status = 0
                        self.updateValues(self.timeframeRender.status)
                    },
                    callCancel: function () {
                        self.timeframeRender.status = 1
                        self.updateValues(self.timeframeRender.status)
                    }
                })
            } else {
                self.timeframeRender.status = 1
                self.updateValues(self.timeframeRender.status)
            }
        },

        load() {
            var self = this
            // Cargar categorías cuando se hace refresh en el formulario
            if (Object.values(self.$store.getters['timeframes/getTimeFrames']()).length == 0) {
                self.$store.dispatch('timeframes/loadTimeFrames', { status: '1,-2' }).then(() => {
                    if (self.$route.name == 'TimeFrames') {
                        if (typeof self.id != 'undefined') {
                            self.idTimeFrame = self.id
                            self.$router.push({
                                name: 'EditTimeFrames',
                                params: { id: self.id }
                            })
                            self.formatHours(self.timeframeRender)
                        } else {
                            self.$store.dispatch('timeframes/addNewTimeFrame').then((id) => {
                                self.idTimeFrame = id
                                self.$router.push({ name: 'AddTimeFrames' })
                                self.formatHours(self.timeframeRender)
                            })
                        }
                    } else {
                        if (typeof self.id != 'undefined') {
                            self.idTimeFrame = self.id
                            self.formatHours(self.timeframeRender)
                        } else {
                            self.$store.dispatch('timeframes/addNewTimeFrame').then((id) => {
                                self.idTimeFrame = id
                                self.formatHours(self.timeframeRender)
                            })
                        }
                    }
                })
            } else {
                if (self.$route.name == 'TimeFrames') {
                    if (typeof self.id != 'undefined') {
                        self.$router.push({
                            name: 'EditTimeFrames',
                            params: { id: self.id }
                        })
                        self.idTimeFrame = self.id
                        self.formatHours(self.timeframeRender)
                    } else {
                        self.$store.dispatch('timeframes/addNewTimeFrame').then((id) => {
                            self.idTimeFrame = id
                            self.$router.push({ name: 'AddTimeFrames' })
                            self.formatHours(self.timeframeRender)
                        })
                    }
                } else {
                    self.$store.dispatch('timeframes/loadTimeFrames', { status: '1,-2' }).then(() => {
                        if (typeof self.id != 'undefined') {
                            self.idTimeFrame = self.id
                            self.formatHours(self.timeframeRender)
                        } else {
                            self.$store.dispatch('timeframes/addNewTimeFrame').then((id) => {
                                self.idTimeFrame = id
                                self.formatHours(self.timeframeRender)
                            })
                        }
                    })
                }
            }
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveTimeframe() {
            var self = this
            self.$overlay.loading()
            this.$store.dispatch('timeframes/validateTimeFrame', this.idTimeFrame).then((response) => {
                if (response.status) {
                    var sendForm = self.$route.params.id ? 'editTimeFrames' : 'addTimeFrames'
                    self.$store.dispatch('timeframes/' + sendForm, self.timeframeRender).then((response) => {
                        self.$store.dispatch('template/loadFilters', { type: 1 }).then(() => {
                            self.goBack()
                            self.$snackbar.open({
                                message: self.$route.params.id ? self.$t('snackbar.edit_success') + ' <b>' + self.timeframeRender.name + '</b>' : self.$t('snackbar.add_success') + ' <b>' + self.timeframeRender.name + '</b>',
                                customClass: 'success',
                                duration: 5000
                            })
                        })
                    })
                } else {
                    self.errors = response.errors
                    self.$overlay.hide()
                    scrollError()
                }
            })
        },

        deleteTimeframe() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.timeframeRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('timeframes/removeTimeFrame', self.timeframeRender.id).then(() => {
                self.$store.dispatch('template/loadFilters', { type: 1 }).then(() => {
                    self.$emit('back')
                    self.$snackbar.open(snackbarData)
                })
            })
        },

        showConfirmDeleteTimeframeModal() {
            var self = this
            const modalMessage = self.$t('timeframe.delete', { name: self.timeframeRender.name })

            this.$popup.delete({ message: modalMessage, callSave: this.deleteTimeframe })
        }
    },

    created() {
        this.load()
    },
    mounted() {},
    updated() {}
}
</script>

<style lang="scss">
#content.issue-state-management {
    .left-container {
        padding-left: 0;

        &.two-items {
            @include flex-direction(column);
            width: 100%;
            padding-right: 30px;

            .error-form {
                &.hour {
                    margin-bottom: 6px;
                    font-family: $text-bold;
                }
            }
            .hour-container {
                @include display-flex();
                .user-answer {
                    @include display-flex();
                    @include flex-direction(column);

                    .label {
                        @include font-size(md);
                        font-family: $text-bold;
                    }
                    .hour-box {
                        width: 100%;
                        padding-right: 10px;
                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .disabled {
        cursor: not-allowed;
    }
}
</style>
