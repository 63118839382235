<template>
    <div class="empty-container" :class="[$mq, type]">
        <div class="content">
            <div class="title-content" v-html="title"></div>
            <div class="description" v-html="description"></div>
            <div class="button-action" v-if="buttonText != undefined">
                <Button bType="clearFilters" :customClass="buttonClass" :bLabel="buttonText" :bCallback="actionButton"></Button>
            </div>
            <div class="optional-link" v-if="link != undefined">
                <router-link :to="link">{{ linkText }}</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EmptyTable',
    props: {
        title: String,
        description: String,
        link: undefined,
        linkText: undefined,
        buttonText: undefined,
        buttonType: { type: String, default: 'create' },
        buttonClass: { type: String, default: 'btn-action' },
        type: { type: String, default: 'default' }
    },
    data() {
        return {
            openReport: true
        }
    },
    computed: {},
    methods: {
        actionButton() {
            this.$emit('buttonAction')
        }
    }
}
</script>
<style lang="scss" scoped>
.empty-container {
    @include display-flex();
    @include justify-content();
    @include align-items(center);
    width: 100%;
    height: 100%;
    pointer-events: all;
    z-index: 10;

    .content {
        @include background($image: img('ellipse_neutro_t30.svg'), $size: 70%);
        @include display-flex();
        @include flex-direction(column);
        @include align-items(flex-start);
        @include justify-content(center);
        padding-left: 100px;
        z-index: 2;
        height: auto;
        width: auto;
        text-align: left;
        position: relative;
        min-width: 400px;
        min-height: 400px;

        .title-content {
            @include font-size(xl);
            font-family: $text-bold;
            color: $color-black;
        }
        .description {
            @include font-size(ml);
            font-family: $text-bold;
            color: $color-black;
        }
        .optional-link {
            @include font-size(sm);
            font-family: $text-medium;
            color: $color-primary-500;
            padding-top: 6px;
        }
        .button-action {
            padding-top: 30px;

            .btn {
                margin: 0;
            }
        }
    }
    .content::before {
        @include background($image: img('alex_cuerpo_07_inverted.svg'));
        content: '';
        display: inline-block;
        height: 300px;
        width: 150px;
        position: absolute;
        top: calc(50% - 150px + 10px);
        left: -35px;
    }

    &.supervise {
        @include display-flex();
        @include justify-content();
        .content::before {
            @include background($image: img('alex_cuerpo_07.svg'));
            content: '';
            display: inline-block;
            height: 300px;
            width: 150px;
            position: absolute;
            top: calc(50% - 150px + 10px);
            left: unset;
            right: -35px;
        }
        .content {
            @include background($image: img('ellipse_neutro_t30_inverted.svg'), $size: 70%);
            @include align-items(flex-end);
            text-align: unset;
            text-align: right;
            padding-left: unset;
            padding-right: 100px;
            margin-left: 30px;
        }
        .title-content {
            @include font-size(xl);
            font-family: $text-bold;
            color: $color-black;
            height: auto;
            max-width: 300px;
            text-align: right;
            margin-top: -30px;
            padding-bottom: 10px;
        }
        .description {
            @include font-size(ml);
            font-family: $text-medium;
            color: $color-black;
            max-width: 250px;
            height: auto;
            text-align: right;
        }
        .button-action {
            padding-top: unset;
            margin-top: 30px;

            .btn {
                margin: 0;
            }
        }
    }
    &.portrait {
        .content {
            min-width: 0;
            max-width: 100%;
            margin-left: 0;
            transform: scale(0.85);
        }
    }
}
</style>
