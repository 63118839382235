<template>
    <div class="table-status">
        <span class="estate">
            <span class="ball-color" :class="status"></span>
            <span class="text" v-if="text" v-html="text"></span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'TableStatuses',
    props: {
        status: { default: 'active' },
        text: { default: undefined }
    },
    data() {
        return {
            showLess: true
        }
    },
    computed: {},
    methods: {
        removeItem(tag) {
            var property = this.value
            var index = this.tags.findIndex(function (o) {
                return o[property] === tag[property]
            })
            if (index !== -1) this.tags.splice(index, 1)

            //Code for new autocomplete input
            if (this.newInput) {
                this.$emit('inputAutocompleteRemovable', index)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.estate {
    background-color: $color-neutral-300;
    padding: 0px 6px 0px 6px;
    border-radius: 4px;
}
.ball {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.ball-color {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-right: 3px;

    &.active {
        background-color: $color-success-500;
    }
    &.inactive {
        background-color: $color-neutral-600;
    }
    &.pending {
        background-color: $color-warning-500;
    }
    &.ended {
        background-color: $color-error-500;
    }
}
</style>
